import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Comment } from "ts/comments";
import { fetchCategories, fetchTags } from "./thunk";
import { Category, Tag } from "@explorance/mly-types";

export type CreatableSelectOption = {
  label: string;
  value: string;
};

type PinState = {
  comment: Comment;
  selectedCategory: CreatableSelectOption;
  tags: CreatableSelectOption[];
  categories: Category[];
  tagOptions: Tag[];
};

const initialState: PinState = {
  comment: null,
  selectedCategory: null,
  tags: [],
  categories: [],
  tagOptions: [],
};

const PinSlice = createSlice({
  name: "pinSlice",
  initialState,
  reducers: {
    setComment: (state, action: PayloadAction<Comment>) => {
      state.comment = action.payload;
      if (state.comment?.pin?.category) {
        state.selectedCategory = {
          label: action.payload.pin.category,
          value: action.payload.pin.category,
        };
      }
      if (state.comment?.pin?.tags) {
        state.tags = action.payload.pin.tags.map((tag) => ({ value: tag, label: tag }));
      }
    },
    setSelectedCategory: (state, action: PayloadAction<CreatableSelectOption>) => {
      if (action.payload?.label.trim().length > 0 || !action.payload)
        state.selectedCategory = action.payload;
    },
    setTags: (state, action: PayloadAction<CreatableSelectOption[]>) => {
      if (
        action.payload[action.payload.length - 1]?.label?.trim().length > 0 ||
        action.payload.length === 0
      ) {
        state.tags = action.payload;
      }
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
    builder.addCase(fetchTags.fulfilled, (state, action) => {
      state.tagOptions = action.payload;
    });
  },
});

export const { setComment, setSelectedCategory, setTags, clearState } = PinSlice.actions;

export default PinSlice.reducer;
