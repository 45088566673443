import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { upperFirst } from "lodash";
import axios, { AxiosError } from "axios";

import { routes } from "routes";

import { getCommentStatisticsByAnalysisId, getCommentsByAnalysisId } from "services/analysis";
import { removeHangingSeparatorsFromSearch, statisticsURLStringToArray } from "utils/comments";
import { getCustomModelId } from "utils/getCustomModelId";
import { useResource } from "hooks/useResource";
import useClickOutside from "hooks/useClickOutside";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { useQueryParams } from "hooks/useQueryParams";
import { isAlertRangeActive } from "utils/isAlertRangeActive";
import { formatSearchForFetchRequest } from "utils/formatters";
import { resetPageScroll } from "utils/resetPageScroll";
import { formatDemographicsForApi } from "utils/filters";
import { voidFunc } from "utils/voidFunc";

import { AnalysisContext } from "context/AnalysisContext";
import {
  handleAxiosError,
  setAnalysisError,
  setCommentsSearchType,
  setCommentStatistics,
  setSelectedTopicNodes,
} from "context/AnalysisContext/actions";
import { clearState } from "store/pin/pinSlice";
import { setSearchTerm } from "store/search/searchbarSlice";

import {
  getAvailableCommentViews,
  mapCommentStatisticsForMenu,
} from "components/StatsMenu/helpers";
import { Pagination } from "components/Pagination";
import { SideDrawer } from "components/SideDrawer";
import { PaginationPlaceholder } from "components/Pagination/Placeholder";
import { StatsMenu } from "components/StatsMenu";
import { StatsPlaceholder } from "components/StatsMenu/Placeholder";
import { Searchbar } from "components/_inputs/Searchbar";
import { Select } from "components/_inputs/Select";
import { ErrorScreen } from "components/ErrorScreen";
import { Text } from "components/Text";
import { VerticalSeparator } from "components/Separators/VerticalSeparator";
import { Button } from "components/_buttons/Button";
import { Checkbox } from "components/_inputs/Checkbox";
import { Icon, IconType } from "components/_icons/Icon";

import { FilterPillList } from "common-layouts/FilterSelectionSection/FilterPillList";
import { AnalysisFilterDrawerLayout } from "common-layouts/FilterDrawerLayout";
import { CommentList } from "./_layouts/CommentList";
import { PinPopup } from "./_layouts/Comment/PinActionButton/PinPopup";

import { Comment as CommentModel } from "ts/comments";
import {
  CommentsSearchType,
  CommentsSortingParameter,
  CommentsView,
  LanguageCode,
  RoleType,
  SharingCommentExplorerAccess,
  StatisticsType,
} from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { PageErrorType } from "ts/enums/pageErrorType";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { Pin } from "ts/pin";
import { unpinComment } from "services/comments";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { RedactCommentModal, RedactCommentModalType } from "./_layouts/Comment/RedactCommentModal";

const DEFAULT_LIST_LIMIT = 50;

type checkedComment = {
  id: number;
  hasPin: boolean;
};

export const CommentsPage = () => {
  const { searchTerm } = useAppSelector((state) => state.search);
  const { currentUser } = useAppSelector((state) => state.auth);
  const { getResource } = useResource();

  const [state, analysisContextDispatch] = useContext(AnalysisContext);

  const dispatch = useAppDispatch();

  const [comments, setComments] = useState<CommentModel[]>([]);
  const [checkedComments, setCheckedComments] = useState<checkedComment[]>([]);
  const [allCommentsSelected, setAllCommentsSelected] = useState<boolean>(false);
  const [commentsLoading, setCommentsLoading] = useState<boolean>(true);
  const [showUnpinCommentsModal, setShowUnpinCommentsModal] = useState(false);
  const [commentsStatisticsLoading, setCommentsStatisticsLoading] = useState<boolean>(true);
  const [sideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [currentCommentsCount, setCurrentCommentsCount] = useState<number>();
  const [selectedCommentsView, setSelectedCommentsView] = useState<CommentsView>(null);
  const [isPinPopupOpen, setIsPinPopupOpen] = useState<boolean>(false);
  const [pinAnchorEl, setPinAnchorEl] = useState<null | HTMLElement>(null);

  const bulkPinActionRef = useClickOutside(() => setIsPinPopupOpen(false));

  const searchingTypeOptions = [
    {
      value: CommentsSearchType.Comments,
      label: getResource("comments.search.dropdown.comments"),
    },
    {
      value: CommentsSearchType.Notes,
      label: getResource("comments.search.dropdown.notes"),
    },
    {
      value: CommentsSearchType.Tags,
      label: getResource("comments.search.dropdown.tags"),
    },
  ];

  const commentSortingOptions = [
    { label: getResource("comments.sortDropdown.default"), value: null },
  ].concat(
    Object.keys(CommentsSortingParameter).map((csp) => {
      return {
        label: getResource(`comments.sortBy.${upperFirst(csp)}`),
        value: csp,
      };
    })
  );
  const [sortBy, setSortBy] = useState(commentSortingOptions[0]);

  const history = useHistory();
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const { pathname } = useLocation();
  const {
    reset,
    statistics,
    view,
    page,
    sharingPreview,
    sharingId,
    sharedWithPage,
    expandedRowId,
    step,
    sort,
  } = useQueryParams() as {
    reset;
    statistics;
    view;
    page;
    sharingPreview;
    sharingId;
    sharedWithPage;
    expandedRowId;
    step;
    sort;
  };
  const customModelId = getCustomModelId(state);
  const availableCommentViews = getAvailableCommentViews(state.analysisDetails.availableResults);

  // the custom model, threshold, and topics filters + pill shall be shown only in the categorized comments/recommendations views
  const showModelTopicThresholdFilters =
    view === CommentsView.categorizedComments || view === CommentsView.categorizedRecommendations;

  const requestBody = {
    ...(showModelTopicThresholdFilters && {
      topics: state.selectedTopicNodes.map((tn) => tn.fullPath),
    }),
    demographics: formatDemographicsForApi(state.selectedDemographicFilters),
    ...(showModelTopicThresholdFilters && { threshold: state.threshold }),
    statistics: statisticsURLStringToArray(statistics).filter((s) => s !== StatisticsType.total),
    ...(state.selectedColumnFilters.length > 0 && {
      selectedColumns: state.selectedColumnFilters,
    }),
    ...(state.analysisDetails?.availableResults?.alerts === true &&
      isAlertRangeActive(state.selectedAlertRange) && {
        alertScore: state.selectedAlertRange,
      }),
    ...(state.selectedTranslationLanguage && {
      translationLanguages: [state.selectedTranslationLanguage],
    }),
    ...(state.selectedCategories.length > 0 && {
      categories: state.selectedCategories,
    }),
  };

  const commentViewDropdownOptions = availableCommentViews.map((cv) => ({
    label: getResource(`comments.viewDropdown.${cv}`),
    value: cv,
    isActive: selectedCommentsView === cv,
  }));

  // Update the "page" URL query param if the query is greater than the total number of pages
  const updatePageUrlQueryParam = useCallback(
    (newPageNumber: number) => {
      const params = new URLSearchParams(history.location.search);
      params.set("page", newPageNumber.toString());
      history.replace(history.location.pathname + "?" + params.toString());
    },
    [history]
  );

  const updateSortQueryParam = useCallback(
    (newSort: CommentsSortingParameter) => {
      const params = new URLSearchParams(history.location.search);
      newSort === null ? params.delete("sort") : params.set("sort", newSort);
      history.replace(history.location.pathname + "?" + params.toString());
    },
    [history]
  );

  const handleChangeSearchType = (searchType: CommentsSearchType) => {
    analysisContextDispatch(setCommentsSearchType(searchType));
  };

  const handleSelectView = (view: CommentsView) => {
    setSelectedCommentsView(view);
    setCommentsLoading(true);
    setCommentsStatisticsLoading(true);
    history.push(
      routes.commentsPage(state.analysisDetails.id) +
        getUrlQueryString({
          custom_model_id: customModelId,
          statistics: StatisticsType.total,
          view,
          search: searchTerm,
          sharingPreview,
          sort: sortBy.value || "",
          sharingId,
          sharedWithPage,
          expandedRowId,
          step,
        })
    );
  };

  const onChangePage = (newPage: number) => {
    history.push(
      routes.commentsPage(state.analysisDetails.id) +
        getUrlQueryString({
          custom_model_id: customModelId,
          statistics,
          view,
          page: newPage,
          search: searchTerm,
          sort: sortBy.value || "",
          sharingPreview,
          sharingId,
          sharedWithPage,
          expandedRowId,
          step,
        })
    );
  };

  const updateCommentPin = (ids: number[], pinData?: Pin) => {
    setComments((prev) =>
      prev.map((comment) => (ids.includes(comment.id) ? { ...comment, pin: pinData } : comment))
    );
    setCheckedComments((prev) => prev.map((comment) => ({ ...comment, hasPin: !!pinData })));
    uncheckAllComments();
  };

  const updateCommentNotesCount = (id: number, notesCount: number) => {
    setComments((prev) =>
      prev.map((comment) => (comment.id === id ? { ...comment, notesCount } : comment))
    );
  };

  const checkCommentById = (id: number) => {
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === id ? { ...comment, checked: !comment.checked } : comment
      )
    );

    setCheckedComments((prev) =>
      prev.some((comment) => comment.id === id)
        ? prev.filter((comment) => comment.id !== id)
        : [...prev, { id, hasPin: !!comments.find((c) => c.id === id)?.pin }]
    );
  };

  const uncheckAllComments = () => {
    setComments((prev) => prev.map((comment) => ({ ...comment, checked: false })));
    setCheckedComments([]);
  };

  const toggleSelectAllComments = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setComments((prev) => prev.map((comment) => ({ ...comment, checked: !allCommentsSelected })));

    setCheckedComments((prev) =>
      allCommentsSelected
        ? [...prev.filter((comment) => !comments.find((c) => c.id === comment.id))]
        : [
            ...prev,
            ...comments
              .filter((comment) => !prev.find((c) => c.id === comment.id))
              .map((comment) => ({
                id: comment.id,
                hasPin: !!comments.find((c) => c.id === comment.id)?.pin,
              })),
          ]
    );
    setAllCommentsSelected(!allCommentsSelected);
  };

  const getCommentsCaption = (): string => {
    if (currentCommentsCount === 1) return getResource("comments.countSentence.commentsSingular");
    return getResource("comments.countSentence.comments", currentCommentsCount);
  };

  const handleSort = (option) => {
    setSortBy(commentSortingOptions.find((o) => o.value === option));
    updateSortQueryParam(option);
  };

  const handleSearch = () => {
    setCommentsLoading(true);
    //remove page from query params
    const params = new URLSearchParams(history.location.search);
    params.delete("page");
    history.push(history.location.pathname + "?" + params.toString());
  };

  const fetchCommentStatistics = (): Promise<void> => {
    return new Promise((resolve) => {
      setCommentsStatisticsLoading(true);
      getCommentStatisticsByAnalysisId(
        state.analysisDetails.id,
        customModelId,
        view,
        state.previewUser.id,
        sharingId,
        requestBody,
        cancelTokenSourceRef.current.token
      )
        .then(({ data }) => analysisContextDispatch(setCommentStatistics(data.statistics)))
        .catch((err) => {
          if (!axios.isCancel(err)) {
            analysisContextDispatch(handleAxiosError(err as AxiosError<any>));
          }
        })
        .finally(() => {
          resolve();
          setCommentsStatisticsLoading(false);
        });
    });
  };

  const fetchCommentsData = (): Promise<void> => {
    return new Promise((resolve) => {
      setCommentsLoading(true);
      analysisContextDispatch(setAnalysisError(null));
      if (page <= 0 || isNaN(page)) updatePageUrlQueryParam(1);
      if (page >= 1 && !state.loadingAnalysisDetails) {
        getCommentsByAnalysisId(
          state.analysisDetails.id,
          customModelId,
          page <= 0 || isNaN(page) ? 1 : page,
          view,
          searchTerm
            ? formatSearchForFetchRequest(removeHangingSeparatorsFromSearch(searchTerm))
            : undefined,
          sortBy.value,
          state.previewUser.id,
          sharingId,
          LanguageCode[state.selectedTranslationLanguage],
          searchTerm ? state.commentsSearchType : null,
          {
            ...requestBody,
          },
          cancelTokenSourceRef.current.token
        )
          .then(({ data }) => {
            setComments(
              data.comments.map((comment) => ({
                ...comment,
                checked: checkedComments.some((c) => c.id === comment.id),
              }))
            );
            setCurrentCommentsCount(data?.itemCount);
            const totalPages = Math.ceil(data?.totalCount / DEFAULT_LIST_LIMIT);
            if (page > totalPages && totalPages > 0) updatePageUrlQueryParam(totalPages);
            resetPageScroll();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              analysisContextDispatch(handleAxiosError(err as AxiosError<any>));
            }
          })
          .finally(() => {
            resolve();
            setCommentsLoading(false);
          });
      } else {
        resolve();
      }
    });
  };

  const commentsPagination = () => {
    return (
      <>
        {currentCommentsCount >= 1 ? (
          <Pagination
            currentPage={Number(page)}
            currentItemsCount={currentCommentsCount > 0 ? currentCommentsCount : 1}
            handlePageSelection={onChangePage}
            pageSize={DEFAULT_LIST_LIMIT}
          />
        ) : currentCommentsCount === 0 ? undefined : (
          <PaginationPlaceholder />
        )}
      </>
    );
  };

  const handleClickPinComments = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIsPinPopupOpen((prev) => !prev);
    setPinAnchorEl(event.currentTarget);
  };

  const handleClickUnpinComments = async () => {
    try {
      await unpinComment(checkedComments.map((comment) => comment.id));
      dispatch(showToastSuccess("toast.unpinSuccessful"));
    } catch {
      dispatch(showToastError("toast.defaultError"));
    } finally {
      updateCommentPin(checkedComments.map((comment) => comment.id));
      setShowUnpinCommentsModal(false);
    }
  };

  useEffect(() => {
    if (!isPinPopupOpen) {
      dispatch(clearState());
    }
  }, [isPinPopupOpen, dispatch]);

  useEffect(() => {
    setAllCommentsSelected(comments.every((comment) => comment.checked));
  }, [comments]);

  useEffect(() => {
    return () => {
      // reset search type dropdown
      analysisContextDispatch(setCommentsSearchType(CommentsSearchType.Comments));

      cancelTokenSourceRef.current.cancel("Comments page unmounted"); // eslint-disable-line
    };
  }, []); // eslint-disable-line

  // Update the "custom_model_id" URL query param according to the values from the Context
  useEffect(() => {
    if (state.loadingAnalysisModels) return;

    const params = new URLSearchParams(history.location.search);
    if (customModelId && !params.get("custom_model_id")) {
      params.set("custom_model_id", customModelId.toString());
    } else if (!customModelId) params.delete("custom_model_id");
    history.replace(history.location.pathname + "?" + params.toString());
  }, [history, customModelId, state.loadingAnalysisModels]);

  useEffect(() => {
    if (state.loadingAnalysisModels) return;
    fetchCommentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.selectedDemographicFilters,
    state.selectedTopicNodes,
    state.threshold,
    state.selectedAlertRange,
    state.loadingAnalysisDetails,
    state.selectedColumnFilters,
    state.loadingAnalysisModels,
    state.previewUser.id,
    state.selectedCategories,
    customModelId,
    view,
    state.commentsSearchType,
    statistics,
    sortBy,
    page,
    analysisContextDispatch,
    updatePageUrlQueryParam,
  ]);

  useEffect(() => {
    if (state.loadingAnalysisModels || state.loadingAnalysisDetails) return;
    if (!searchTerm) {
      fetchCommentsData();
    } else {
      dispatch(setSearchTerm(""));
    }
  }, [state.selectedTranslationLanguage]); // eslint-disable-line

  // fetch comment statistics
  useEffect(() => {
    if (state.loadingAnalysisModels) return;
    fetchCommentStatistics();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customModelId,
    view,
    analysisContextDispatch,
    state.selectedAlertRange,
    state.analysisDetails.id,
    state.selectedColumnFilters,
    state.selectedDemographicFilters,
    state.selectedTopicNodes,
    state.selectedCategories,
    state.threshold,
    state.previewUser.id,
    state.loadingAnalysisModels,
  ]);

  // Sets the topic filters to the previous ones, ONLY if we click the back button of the browser AND `reset` is present in the query params
  useEffect(() => {
    return () => {
      if (reset && history.action === "POP") {
        analysisContextDispatch(setSelectedTopicNodes(state.lastSelectedTopicNodes));
      }
    };
  }, [history, analysisContextDispatch, reset, state.lastSelectedTopicNodes, pathname]);

  // Set initial view
  useEffect(() => {
    // if view is present in query params,
    if (view && Object.keys(CommentsView).includes(view)) {
      setSelectedCommentsView(view);
    } else {
      setSelectedCommentsView(CommentsView.allComments);
    }
  }, [view]);

  useEffect(() => {
    if (sort) {
      setSortBy({
        label: getResource(`comments.sortBy.${upperFirst(sort)}`),
        value: sort,
      });
    }
  }, [sort]); // eslint-disable-line

  if (
    state.analysisDetails.sharing &&
    state.analysisDetails.sharing?.commentExplorerAccess !== SharingCommentExplorerAccess.Shared
  ) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <>
      <div className="fade-in">
        <SideDrawer
          render={AnalysisFilterDrawerLayout}
          isOpen={sideDrawerOpen}
          closeHandler={() => setSideDrawerOpen(false)}
          width="600px"
        />
        <StyledPageHeader whiteBackground={state.showWhiteBackground}>
          <FilterPillList
            containerMargin={"0 0 16px 0"}
            openFiltersMenu={() => setSideDrawerOpen(true)}
          />

          <StyledStatsMenuSection>
            {!commentsStatisticsLoading && state.commentStatistics ? (
              <StatsMenu
                menuItems={mapCommentStatisticsForMenu(state.commentStatistics)}
                commentsLoading={commentsLoading}
                commentsViewOptions={commentViewDropdownOptions}
                handleSelectView={handleSelectView}
              />
            ) : (
              <StatsPlaceholder />
            )}
            <StyledSortBySection>
              <span>
                <Text resource="comments.sortBy.label" />:
              </span>
              <Select
                selectedOption={sortBy}
                options={commentSortingOptions}
                dropdownWidth={"auto"}
                handleChange={handleSort}
                buttonSize={ButtonSize.md}
                buttonStyle={{
                  padding: "8px 10px",
                  fontWeight: "normal",
                  fontSize: "15px",
                  gap: "10px",
                }}
                dropdownPosition={{ right: 0 }}
              />
            </StyledSortBySection>
          </StyledStatsMenuSection>
          <StyledSearchSection>
            {currentUser.roleType !== RoleType.Viewer && (
              <>
                <StyledSelectAll onClick={(e) => toggleSelectAllComments(e)}>
                  <Checkbox
                    checked={checkedComments.length > 0}
                    checkedAppearance={
                      allCommentsSelected && comments.length > 0
                        ? CheckboxCheckedAppearance.Default
                        : CheckboxCheckedAppearance.Partial
                    }
                    onChange={voidFunc}
                  />
                  <Text resource="comments.bulkPin.selectAll" />
                </StyledSelectAll>

                <VerticalSeparator
                  height="34px"
                  style={{ margin: "0px 10px", alignSelf: "center" }}
                />
              </>
            )}

            <StyledBulkPinSection hide={checkedComments.length === 0}>
              <StyledPinSection>
                <StyledBulkActionSection ref={bulkPinActionRef}>
                  <Button
                    variant={ButtonVariant.primary}
                    size={ButtonSize.sm}
                    onClick={handleClickPinComments}
                  >
                    <Text resource="comments.bulkPin.button.label" />
                    <Icon type={IconType.chevronDown} size={8} style={{ marginTop: "2px" }} />
                  </Button>
                  <Button
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.sm}
                    onClick={() => setShowUnpinCommentsModal(true)}
                    disabled={!checkedComments.some((comment) => comment.hasPin)}
                    style={{ marginLeft: "10px" }}
                  >
                    <Text resource="comments.bulkUnpin.button.label" />
                  </Button>
                  {isPinPopupOpen && (
                    <PinPopup
                      anchorEl={pinAnchorEl}
                      setIsPopupOpen={setIsPinPopupOpen}
                      bulkCommentIds={checkedComments.map((comment) => comment.id)}
                      updateCommentPin={updateCommentPin}
                    />
                  )}
                </StyledBulkActionSection>

                <StyledSelectedCount>
                  <Text
                    resource={{
                      key:
                        checkedComments.length > 1
                          ? "comments.bulkPin.selectedCount"
                          : "comments.bulkPin.selectedCount.singular",
                      args: [`${checkedComments.length}`],
                    }}
                  />
                </StyledSelectedCount>
                <span onClick={uncheckAllComments}>
                  <Text resource="comments.bulkPin.clearSelection" />
                </span>
              </StyledPinSection>
              <StyledPaginationSection>{commentsPagination()}</StyledPaginationSection>
            </StyledBulkPinSection>

            <StyledSearchPaginationContainer hide={checkedComments.length > 0}>
              <StyledSearchBarSection>
                <StyledSearchTypeContainer>
                  <StyledSearchTypeDropdownLabel>
                    <Text resource="comments.search.dropdown.label" />
                  </StyledSearchTypeDropdownLabel>
                  <Select
                    options={searchingTypeOptions}
                    selectedOption={searchingTypeOptions.find(
                      (searchTypeOption) => searchTypeOption.value === state.commentsSearchType
                    )}
                    handleChange={(option) => handleChangeSearchType(option)}
                    dropdownWidth="144px"
                    buttonStyle={{
                      color: Color.gray50,
                      fontSize: "15px",
                      width: "144px",
                      borderRadius: "2px",
                      height: "39px",
                    }}
                    buttonVariant={ButtonVariant.outlineGray}
                  />
                </StyledSearchTypeContainer>
                <StyledSearchCommentCountContainer>
                  <StyledSearchBarContainer>
                    <Searchbar
                      onSearch={handleSearch}
                      placeholder={getResource("comments.searchInput.placeholder")}
                      customStyles={{
                        border: `1px solid ${Color.sky50}`,
                        width: "100%",
                      }}
                    />
                  </StyledSearchBarContainer>
                  {state.commentStatistics && currentCommentsCount >= 0 && !commentsLoading && (
                    <StyledCommentsCount>
                      <StyledCommentCountCaption>{getCommentsCaption()}</StyledCommentCountCaption>
                    </StyledCommentsCount>
                  )}
                </StyledSearchCommentCountContainer>
              </StyledSearchBarSection>

              {commentsPagination()}
            </StyledSearchPaginationContainer>
          </StyledSearchSection>
        </StyledPageHeader>

        <CommentList
          comments={comments}
          loading={commentsLoading || commentsStatisticsLoading}
          searchField={searchTerm}
          selectionModeActive={checkedComments.length > 0}
          checkCommentById={checkCommentById}
          updateCommentPin={updateCommentPin}
          updateCommentNotesCount={updateCommentNotesCount}
        />
      </div>
      <ActionConfirmationModal
        isOpen={showUnpinCommentsModal}
        title={<Text resource="modal.deletePins.title" />}
        caption={<Text resource="modal.deletePins.caption" />}
        actionButtonLabel={<Text resource="modal.deletePins.confirmation.button" />}
        onCancel={() => setShowUnpinCommentsModal(false)}
        onClose={() => setShowUnpinCommentsModal(false)}
        onClickActionButton={handleClickUnpinComments}
      />
      <RedactCommentModal type={RedactCommentModalType.Manual} />
    </>
  );
};

const StyledPageHeader = styled.div<{ whiteBackground: boolean }>`
  z-index: ${ZIndexStackingContext.medium};
  position: sticky;
  top: 100px;
  background-color: ${({ whiteBackground }) => (whiteBackground ? Color.white : "transparent")};
  transition: background-color 100ms ease-in-out 50ms;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

const StyledSearchPaginationContainer = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? "none" : "flex")};
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  font-size: 0.875em;
`;

const StyledStatsMenuSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSortBySection = styled.div`
  display: flex;
  align-items: center;
  > span {
    color: ${Color.gray50};
    font-size: 15px;
    margin-right: 10px;
  }
`;

const StyledCommentsCount = styled.div`
  padding-left: 10px;
  font-size: 15px;
  color: ${Color.black};
`;

const StyledCommentCountCaption = styled.div`
  display: flex;
  justify-content: left;
  font-weight: bold;
  margin-left: 5px;
`;

const StyledSearchBarSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding-right: 8px;
`;

const StyledSearchCommentCountContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledSearchTypeDropdownLabel = styled.div`
  color: ${Color.gray50};
  font-size: 15px;
  white-space: nowrap;
  min-width: 60px;
`;

const StyledSearchBarContainer = styled.div`
  flex-grow: 1;
`;

const StyledSearchTypeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledBulkPinSection = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? "none" : "flex")};
  flex-grow: 1;
  align-items: center;
  gap: 12px;
`;

const StyledSelectAll = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 15px;
  padding-left: 6px;
  cursor: pointer;
`;

const StyledPinSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  flex-grow: 1;
  span {
    font-size: 12px;
    font-weight: bold;
    color: ${Color.blue50};
    text-decoration: underline;
    cursor: pointer;
  }
  Button {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

const StyledSelectedCount = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

const StyledPaginationSection = styled.div`
  font-size: 0.875em;
`;

const StyledSearchSection = styled.div`
  display: flex;
  flex-direction: row;
  height: 39px;
`;

const StyledBulkActionSection = styled.div`
  display: flex;
`;
