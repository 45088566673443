import React from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import { UserDemographic } from "context/UserProfileContext";

import { Select } from "components/_inputs/Select";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import { RedactionCustomCondition } from "ts/sharing";
import { Color } from "ts/enums/color";
import { DemographicFilterHeader } from "ts/filters/demographicFilter";
import { ButtonVariant } from "ts/enums/button";
import { useAppSelector } from "store";

type Props = {
  index: number;
  customConditionRedactions: RedactionCustomCondition;
  savedCustomConditions: RedactionCustomCondition[];
  analysisDemographicHeaders: DemographicFilterHeader[];
  userDemographics: UserDemographic[];
  updateRedactionCustomConditions: (
    updatedCustomConditions: RedactionCustomCondition[],
    noSave?: boolean
  ) => void;
};

export const CustomRedactionCondition = ({
  index,
  customConditionRedactions,
  savedCustomConditions,
  analysisDemographicHeaders,
  userDemographics,
  updateRedactionCustomConditions,
}: Props) => {
  const state = useAppSelector((state) => state.sharing);

  // functions
  const selectRedactionVersion = (name: string) => {
    const selectedVersion = state.redactionVersions?.find((version) => version.name === name);
    const copy = cloneDeep(savedCustomConditions);
    copy[index].redactionVersion = selectedVersion.name;
  };

  const selectCommentersDemographicOption = (name: string) => {
    const selectedHeader = analysisDemographicHeaders.find(
      (analysisDemHeader) => analysisDemHeader.name === name
    );
    const copy = cloneDeep(savedCustomConditions);
    copy[index].analysisDemographic = selectedHeader.name;
    updateRedactionCustomConditions(copy);
  };

  const selectUserDemographicOption = (name: string) => {
    const selectedUserDemographic = userDemographics.find((userDem) => userDem.name === name);
    const copy = cloneDeep(savedCustomConditions);
    copy[index].userDemographic = selectedUserDemographic.name;
    updateRedactionCustomConditions(copy);
  };

  const deleteCondition = () => {
    const copy = cloneDeep(savedCustomConditions);
    copy.splice(index, 1);
    updateRedactionCustomConditions(copy);
  };

  return (
    <StyledCustomCondition>
      <span>
        <Text resource="sharing.redaction.customCondition.label" />
      </span>

      <StyledRedactionSelectContainer>
        <Select
          selectedOption={{
            label: customConditionRedactions.redactionVersion,
            value: customConditionRedactions.redactionVersion,
          }}
          options={state.redactionVersions?.map((version) => ({
            label: version.name,
            value: version.id?.toString(),
          }))}
          handleChange={selectRedactionVersion}
          buttonStyle={{ width: "100%" }}
          selectStyle={{ width: "100%" }}
          placeholderKey="sharing.permissionForm.customCondition.defaultRedactionVersion.label"
        />
      </StyledRedactionSelectContainer>
      <Text resource="sharing.redaction.customCondition.label.ifUsers" />

      <Select
        selectedOption={{
          label: customConditionRedactions.analysisDemographic,
          value: customConditionRedactions.analysisDemographic,
        }}
        options={analysisDemographicHeaders.map((analysisDemHeader) => ({
          label: analysisDemHeader.name,
          value: analysisDemHeader.name,
        }))}
        handleChange={selectCommentersDemographicOption}
        dropdownWidth="100%"
        buttonStyle={{ width: "100%" }}
        buttonVariant={ButtonVariant.outlineGray}
        dropdownMaxHeight="260px"
        placeholderKey="sharing.permissionForm.customCondition.defaultCommentersDemographic.label"
      />

      <span>
        <Text resource="sharing.redaction.customCondition.label.equalsTo" />
      </span>

      <Select
        selectedOption={{
          label: customConditionRedactions.userDemographic,
          value: customConditionRedactions.userDemographic,
        }}
        options={userDemographics.map((userDem) => ({
          label: userDem.name,
          value: userDem.name,
        }))}
        handleChange={selectUserDemographicOption}
        dropdownWidth="100%"
        buttonStyle={{ width: "100%" }}
        buttonVariant={ButtonVariant.outlineGray}
        dropdownMaxHeight="260px"
        placeholderKey="sharing.permissionForm.customCondition.defaultUserDemographic.label"
      />

      <StyledDeleteContainer onClick={deleteCondition}>
        <Icon type={IconType.xCircle} color={Color.gray30} size={14} />
      </StyledDeleteContainer>
    </StyledCustomCondition>
  );
};

const StyledCustomCondition = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    /* width: fit-content; */
    font-size: 15px;
    color: ${Color.gray50};
  }

  /* setting the widths of Select dropdowns */
  @media (max-width: 1365px) {
    > div:nth-of-type(1) {
      width: 15%;
    }

    > div:nth-of-type(2),
    > div:nth-of-type(3) {
      width: 21%;
    }
  }

  @media (min-width: 1366px) {
    > div:nth-of-type(1) {
      width: 20%;
    }
    > div:nth-of-type(2),
    > div:nth-of-type(3) {
      width: 24%;
    }
  }
`;

const StyledDeleteContainer = styled.span`
  cursor: pointer;
`;

const StyledRedactionSelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
