import React from "react";
import styled, { css } from "styled-components";

import { Button } from "components/_buttons/Button";
import { CloseButton } from "components/_buttons/CloseButton";
import { Icon, IconType } from "components/_icons/Icon";
import { Modal } from "components/_modals/Modal";
import { Text } from "components/Text";

import { useAppDispatch, useAppSelector } from "store";
import {
  closeRedactCommentModal,
  hideComment,
  removeComment,
  setRedactedCommentValue,
} from "store/redaction/redactCommentSlice";
import { useResource } from "hooks/useResource";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";

export enum RedactCommentModalType {
  Manual = "Manual",
  Request = "Request",
}

type Props = {
  type: RedactCommentModalType;
};

export const RedactCommentModal = ({ type }: Props) => {
  const dispatch = useAppDispatch();
  const { isRedactCommentModalOpen, originalComment, redactedComment } = useAppSelector(
    (state) => state.redactComment
  );
  const { getResource } = useResource();

  const handleChangeRedactedComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setRedactedCommentValue(e.target.value));
  };

  const submissionResourceKey = {
    [RedactCommentModalType.Manual]: "comments.redactModal.button.redactComment",
    [RedactCommentModalType.Request]: "comments.redactModal.button.requestRedaction",
  }[type];

  // Render
  if (!originalComment) {
    return null;
  }

  return (
    <Modal
      isOpen={isRedactCommentModalOpen}
      width="848px"
      height="auto"
      padding="20px 24px 24px 24px"
      margin="10% auto"
    >
      <StyledHeaderSection>
        <h2>
          <Text resource="comments.redactModal.header" />
        </h2>
        <CloseButton
          titleKey="button.close"
          closeHandler={() => dispatch(closeRedactCommentModal())}
        />
      </StyledHeaderSection>
      <StyledOriginalCommentSection>
        <h3>
          <Text resource="comments.redactModal.originalComment.header" />
        </h3>
        <div>
          <p>{originalComment.value}</p>
        </div>
      </StyledOriginalCommentSection>
      <StyledRedactedCommentHeaderSection>
        <h3>
          <Text resource="comments.redactModal.redactedComment.header" />
        </h3>
        <StyledRedactionOptions>
          <Button
            variant={redactedComment.isHidden ? ButtonVariant.secondary : ButtonVariant.outlineGray}
            size={ButtonSize.sm}
            onClick={() => dispatch(hideComment())}
          >
            <Icon type={IconType.eyeMinus} size={12} style={{ marginRight: 7 }} />
            <Text resource="comments.redactModal.button.hideComment" />
          </Button>
          <Button
            variant={
              redactedComment.isRemoved ? ButtonVariant.secondary : ButtonVariant.outlineGray
            }
            size={ButtonSize.sm}
            onClick={() => dispatch(removeComment())}
          >
            <Icon type={IconType.minusCircle} style={{ marginRight: 7 }} size={14} />
            <Text resource="comments.redactModal.button.removeComment" />
          </Button>
        </StyledRedactionOptions>
      </StyledRedactedCommentHeaderSection>
      {redactedComment.isRemoved ? (
        <StyledRemovedCommentBanner>
          <Text resource="comments.redactModal.redactedComment.removed" />
        </StyledRemovedCommentBanner>
      ) : (
        <StyledRedactedCommentInput
          value={
            redactedComment.isHidden
              ? getResource("comments.redactModal.redactedComment.redacted")
              : redactedComment.value
          }
          onChange={handleChangeRedactedComment}
          isHidden={redactedComment.isHidden}
          disabled={redactedComment.isHidden}
        />
      )}
      <StyledSubmissionSection>
        <Button
          variant={ButtonVariant.light}
          size={ButtonSize.md}
          onClick={() => dispatch(setRedactedCommentValue(originalComment.value))}
        >
          <Text resource="comments.redactModal.button.revertComment" />
        </Button>
        <Button
          variant={ButtonVariant.primary}
          size={ButtonSize.md}
          onClick={(e) => dispatch(setRedactedCommentValue(e.target.value))}
        >
          <Text resource={submissionResourceKey} />
        </Button>
      </StyledSubmissionSection>
    </Modal>
  );
};

const StyledHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;
  color: ${Color.gray50};

  h2 {
    font-size: 20px;
    margin: 0;
  }
`;

const StyledOriginalCommentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    font-size: 16px;
    margin: 0;
    color: ${Color.gray50};
  }

  p {
    font-size: 14px;
    margin: 0;
    color: ${Color.gray40};
    font-size: 14px;
  }

  div {
    padding: 8px 10px;
    border-radius: 5px;
    background-color: ${Color.neutral20};
    max-height: 150px;
    overflow-y: auto;
  }
`;

const StyledRedactionOptions = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 10px 0;
`;

const StyledRedactedCommentHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Color.gray50};

  h3 {
    font-size: 16px;
    margin: 0;
  }
`;

const StyledRemovedCommentBanner = styled.div`
  background-color: ${Color.red10};
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  text-align: center;
  padding: 8px;
`;

const StyledRedactedCommentInput = styled.textarea<{ isHidden?: boolean }>`
  padding: 10px;
  height: 100px;
  width: 100%;
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  resize: none;
  color: ${Color.gray50};
  ${({ isHidden }) =>
    isHidden &&
    css`
      background-color: ${Color.neutral20};
    `}

  &:focus {
    outline: 1px solid ${Color.blue50};
  }
`;

const StyledSubmissionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
  gap: 13px;
`;
