export enum UserSortingParameter {
  Username = "username",
  FirstName = "firstName",
  LastName = "lastName",
  RoleType = "roleType",
  LastUpdated = "lastUpdated",
  LastLogin = "lastLogin",
}

export enum AnalysisSortingParameter {
  Name = "name",
  AnalysisOwner = "analysisOwner",
  ModelFamily = "modelFamily",
  CommentCount = "commentCount",
  TopicInsightCount = "topicInsightCount",
  RecommendationCount = "recommendationCount",
  AlertCount = "alertCount",
  LastUpdated = "lastUpdated",
}

export enum TopicsSortingParameter {
  Topics = "Topics",
  Comments = "Comments",
  // sentiments view
  Insights = "Insights",
  Positive = "Positive",
  Negative = "Negative",
  Neutral = "Neutral",
  Mixed = "Mixed",
  NotExplicit = "NotExplicit",
  Sentiments = "Sentiments",
  // recommendation view
  Recommendations = "Recommendations",
  DoMore = "DoMore",
  DoLess = "DoLess",
  Start = "Start",
  Stop = "Stop",
  Continue = "Continue",
  Change = "Change",
}

export enum SentimentsViewTopicsSorting {
  Topics = TopicsSortingParameter.Topics,
  Comments = TopicsSortingParameter.Comments,
  Insights = TopicsSortingParameter.Insights,
  Positive = TopicsSortingParameter.Positive,
  Negative = TopicsSortingParameter.Negative,
  Neutral = TopicsSortingParameter.Neutral,
  Mixed = TopicsSortingParameter.Mixed,
  NotExplicit = TopicsSortingParameter.NotExplicit,
  Sentiments = TopicsSortingParameter.Sentiments,
}

export enum RecommendationsViewTopicsSorting {
  Topics = TopicsSortingParameter.Topics,
  Comments = TopicsSortingParameter.Comments,
  Recommendations = TopicsSortingParameter.Recommendations,
  DoMore = TopicsSortingParameter.DoMore,
  DoLess = TopicsSortingParameter.DoLess,
  Start = TopicsSortingParameter.Start,
  Stop = TopicsSortingParameter.Stop,
  Continue = TopicsSortingParameter.Continue,
  Change = TopicsSortingParameter.Change,
}

export enum DownloadedFilesListSortingParameterForBackendMap {
  FileName = "exportName",
  FromAnalysis = "analysisName",
  Status = "status",
  Model = "modelFamily",
  DateGenerated = "dateGenerated",
}

export enum VariableMappingsPresetListSortingParameter {
  Name = "name",
  VariableCount = "variableCount",
  DateCreated = "dateCreated",
  DateForBackend = "createdDate",
  LastUpdated = "lastUpdated",
}

export enum CustomModelsListSortingParameter {
  CustomModelName = "customModelName",
  Env = "env",
  Model = "model",
  DateCreated = "dateCreated",
  LastUpdated = "lastUpdated",
}

export enum CustomModelListSortingParameterForBackendMap {
  CustomModelName = "name",
  Model = "family",
  Env = "env",
  DateCreated = "createdDate",
  LastUpdated = "lastUpdated",
}

export enum RedactionRequestListSortingParameter {
  AnalysisName = "analysisName",
  SubmittedBy = "submittedBy",
  OriginalComment = "originalComment",
  RedactedComment = "redactedComment",
  Status = "status",
  RequestDate = "requestDate",
}
