import { SortingOrder } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DropdownMenuItem } from "ts/dropdown";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { RedactionRequestStatus } from "ts/enums/redaction";
import { RedactionRequestListSortingParameter } from "ts/enums/sorting";
import { RedactionRequest } from "ts/redaction";
import { RequestsView } from "ts/enums/requestListView";

type RedactionRequestListSorting = {
  sortColumn: RedactionRequestListSortingParameter;
  sortOrder: SortingOrder;
};
type RedactionRequestListState = {
  redactionRequests: RedactionRequest[];
  currentPage: number;
  totalRedactionRequestCount: number;
  currentRedactionRequestCount: number;
  searchField: string;
  listView: RequestsView;
  sortColumn: RedactionRequestListSortingParameter;
  sortOrder: SortingOrder;
  isSearchReset: boolean;
  currentStatusFilters: string[];
  emptyStateType: EmptyStateType;
  showDeleteConfirmation: boolean;
  allStatusFilters: string[];
  currentEditedRow: RedactionRequest;
  dropdownMenuContent: DropdownMenuItem[];
  isLoading: boolean;
  selectedRedactionRequests: number[];
};

const initialState: RedactionRequestListState = {
  redactionRequests: [
    {
      id: 1,
      analysisName: "My analysis",
      isSelected: false,
      submittedBy: {
        id: 1,
        firstname: "King",
        lastname: "Charles",
      },
      originalComment:
        "John Smith always strives to create an inclusive environment, and gives you the tools to accomplish it. Works schedule n",
      redactedComment:
        "John Smith always strives to create an inclusive, and gives you the tools to accomplish it. Works schedule n",
      status: RedactionRequestStatus.pending,
      requestDate: "2024/03/12",
    },
    {
      id: 2,
      analysisName: "My analysis",
      isSelected: false,
      submittedBy: {
        id: 1,
        firstname: "King",
        lastname: "Charles",
      },
      originalComment:
        "John Smith always strives to create an inclusive environment, and gives you the tools to ",
      redactedComment:
        "John Smith always strives to create an inclusive, and gives you the tools to accomplish it. Works schedule n",
      status: RedactionRequestStatus.pending,
      requestDate: "2024/03/13",
    },
  ],
  currentPage: 1,
  totalRedactionRequestCount: 2,
  currentRedactionRequestCount: 2,
  listView: RequestsView.MyRequests,
  searchField: "",
  currentStatusFilters: [],
  sortColumn: RedactionRequestListSortingParameter.RequestDate,
  sortOrder: SortingOrder.DESC,
  isSearchReset: false,
  emptyStateType: EmptyStateType.noListResults,
  allStatusFilters: [],
  showDeleteConfirmation: false,
  dropdownMenuContent: null,
  currentEditedRow: null,
  isLoading: false,
  selectedRedactionRequests: [],
};

const RedactionRequestListSlice = createSlice({
  name: "redactionRequestListSlice",
  initialState,
  reducers: {
    setShowDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showDeleteConfirmation = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearchField: (state, action: PayloadAction<string>) => {
      state.searchField = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAllStatusFilters: (state, action: PayloadAction<string[]>) => {
      state.allStatusFilters = action.payload;
    },
    toggleSelectAll: (state) => {
      const isAllSelected = state.redactionRequests.every((req) => req.isSelected);
      state.redactionRequests = state.redactionRequests.map((req) => ({
        ...req,
        isSelected: !isAllSelected,
      }));
      if (isAllSelected) {
        state.selectedRedactionRequests = state.selectedRedactionRequests.filter(
          (selectedReq) => !state.redactionRequests.map((req) => req.id).includes(selectedReq)
        );
      } else {
        state.selectedRedactionRequests = state.redactionRequests.map((req) => req.id);
      }
    },
    toggleRedactionRequestSelection: (state, action: PayloadAction<number>) => {
      const redactionIndex = state.redactionRequests.findIndex((req) => req.id === action.payload);
      state.redactionRequests[redactionIndex].isSelected =
        !state.redactionRequests[redactionIndex].isSelected;
      state.redactionRequests[redactionIndex].isSelected
        ? state.selectedRedactionRequests.push(action.payload)
        : state.selectedRedactionRequests.splice(
            state.selectedRedactionRequests.findIndex((el) => el === action.payload),
            1
          );
    },
    clearSelection: (state) => {
      state.selectedRedactionRequests = [];
      state.redactionRequests = state.redactionRequests.map((req) => ({
        ...req,
        isSelected: false,
      }));
    },
    setCurrentStatusFilters: (state, action: PayloadAction<string[]>) => {
      state.currentStatusFilters = action.payload;
    },
    setIsSearchReset: (state, action: PayloadAction<boolean>) => {
      state.isSearchReset = action.payload;
    },
    setListView: (state, action: PayloadAction<RequestsView>) => {
      state.listView = action.payload;
    },
    updateRedactionRequestListSorting: (
      state,
      action: PayloadAction<RedactionRequestListSorting>
    ) => {
      state.currentPage = 1;
      state.sortOrder = action.payload.sortOrder;
      state.sortColumn = action.payload.sortColumn;
    },
    setCurrentEditedRow: (state, action: PayloadAction<RedactionRequest>) => {
      state.currentEditedRow = action.payload;
    },
    setDropdownMenuContent: (state, action: PayloadAction<DropdownMenuItem[]>) => {
      state.dropdownMenuContent = action.payload;
    },
    setEmptyStateType: (state, action: PayloadAction<EmptyStateType>) => {
      state.emptyStateType = action.payload;
    },
    changeSelectedDropdownItem: (state, action: PayloadAction<RequestsView>) => {
      state.dropdownMenuContent = state.dropdownMenuContent.map((item) => ({
        ...item,
        isActive: item.value === action.payload,
      }));
    },
    changeListView: (state, action: PayloadAction<RequestsView>) => {
      state.currentPage = 1;
      state.listView = action.payload;
      state.sortOrder = SortingOrder.DESC;
      state.sortColumn = RedactionRequestListSortingParameter.RequestDate;
    },
    clearState: () => initialState,
  },
});
export const {
  setShowDeleteConfirmation,
  updateRedactionRequestListSorting,
  setCurrentPage,
  setSearchField,
  setIsSearchReset,
  setCurrentEditedRow,
  setCurrentStatusFilters,
  toggleRedactionRequestSelection,
  setIsLoading,
  setListView,
  setAllStatusFilters,
  changeSelectedDropdownItem,
  toggleSelectAll,
  changeListView,
  setEmptyStateType,
  setDropdownMenuContent,
  clearState,
  clearSelection,
} = RedactionRequestListSlice.actions;
export default RedactionRequestListSlice.reducer;
