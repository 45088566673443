import { FileUploadStatus, SocketEvent } from "@explorance/mly-types";
import { BE_IMPORT_TASK_PROGRESS_MULTIPLIER } from "assets/constants/import";
import { useEffect, useState } from "react";
import { getFileById } from "services/files";
import { useAppDispatch, useAppSelector } from "store";
import {
  setImportProgressPercentage,
  setValidationProgressPercentage,
} from "store/analysisSettings/dataSourceSlice";

export const useDataSourceProgressBar = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.dataSource);

  const socket = useAppSelector((state) => state.wsStream.socket);
  const [validationBuffer, setValidationBuffer] = useState<number>(0);
  const [importBuffer, setImportBuffer] = useState<number>(0);
  const [validationProgress, setValidationProgress] = useState<number>(0);
  const [importProgress, setImportProgress] = useState<number>(0);

  useEffect(() => {
    if (state.validationProgressPercentage > validationProgress && validationBuffer > 0) {
      const timeoutId = setTimeout(() => {
        setValidationProgress((prev) => prev + validationBuffer);
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [validationBuffer, validationProgress]); //eslint-disable-line

  useEffect(() => {
    if (!socket) return;

    socket.on(SocketEvent.ChangedImportProgressBar, (data) => {
      dispatch(setValidationProgressPercentage(data.percentage));
      setValidationBuffer((data.percentage - validationProgress) / 10);
    });

    return () => {
      socket.off(SocketEvent.ChangedImportProgressBar, (data) =>
        dispatch(setValidationProgressPercentage(data.percentage))
      );
    };
  }, [dispatch, socket]); //eslint-disable-line

  useEffect(() => {
    if (state.importProgressPercentage > importProgress && importBuffer > 0) {
      const timeoutId = setTimeout(() => {
        setImportProgress((prev) => prev + importBuffer);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [importProgress, importBuffer]); // eslint-disable-line

  useEffect(() => {
    if (!state.importModalFileId) return;
    getFileById(state.importModalFileId).then((res) => {
      if (res.data.uploadStatus !== FileUploadStatus.WaitingForInput) {
        setImportProgress(res.data.progress);
      }
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    let timer;
    if (state.importModalFileId && state.importModalOpen) {
      timer = setInterval(() => {
        getFileById(state.importModalFileId).then((res) => {
          if (res.data.uploadStatus === FileUploadStatus.WaitingForInput) {
            clearInterval(timer);
            return;
          } else {
            dispatch(
              setImportProgressPercentage(res.data.progress * BE_IMPORT_TASK_PROGRESS_MULTIPLIER)
            );
            setImportBuffer(
              (res.data.progress * BE_IMPORT_TASK_PROGRESS_MULTIPLIER - importProgress) / 10
            );
          }
        });
      }, 2000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [state.importModalFileId]); // eslint-disable-line

  return { importProgress, validationProgress };
};
