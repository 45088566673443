import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import { useAppDispatch, useAppSelector } from "store";
import {
  fetchAllAnalysisFilesSummary,
  fetchFileSummary,
  handleSaveFileSettings,
} from "store/analysisSettings/thunks";
import {
  resetDataSource,
  setHasImportError,
  setImportModalFileId,
  setImportModalOpen,
  setLoadingReimport,
} from "store/analysisSettings/dataSourceSlice";
import { setAnalysisList as setHomepageAnalysisList } from "store/home/homePageSlice";
import { setAnalysisList } from "store/analysis/analysisListSlice";

import { Modal } from "components/_modals/Modal";
import { ImportSettingsStep } from "./ImportSettingsStep";
import { FileUploadInput } from "components/_inputs/FileUpload";
import { UploadFailedScreen } from "./UploadFailedScreen";
import { UploadFileStep } from "./UploadFileStep";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

import { FileUploadStatus } from "@explorance/mly-types";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { DataSourceModalType } from "ts/enums/dataSourceModalType";

import { uploadFile } from "services/analysis";

const modalStyles = {
  content: {
    margin: "auto",
    boxShadow: "0px 4px 16px #B6BACB29",
    width: "fit-content",
    height: "fit-content",
    border: `1px solid ${Color.neutral30}`,
    borderRadius: "5px",
    backgroundColor: Color.white,
    transition: "height .5s ease-in-out",
    overflow: "hidden",
  },
};

type Props = {
  fileUploadStatus: FileUploadStatus;
  modalType: DataSourceModalType;
  importSuccessCallback?: () => void;
};

export const DataSourceModal = ({ fileUploadStatus, modalType, importSuccessCallback }: Props) => {
  const state = useAppSelector((state) => state.dataSource);
  const homepageAnalysisList = useAppSelector((state) => state.home).analysisList;
  const analysisListPageAnalysisList = useAppSelector((state) => state.analysisList).analysisList;
  const dispatch = useAppDispatch();

  const inputFileRef = useRef<HTMLInputElement>();
  const fileImportFailed = [FileUploadStatus.Failed, FileUploadStatus.ImportUploadError].includes(
    fileUploadStatus
  );

  const handleCloseModal = () => {
    dispatch(resetDataSource());
  };

  const step = [
    FileUploadStatus.Uploading,
    FileUploadStatus.Failed,
    FileUploadStatus.ImportUploadError,
  ].includes(fileUploadStatus)
    ? 1
    : 2;

  const handleSave = async () => {
    const fns = {
      1: () => dispatch(setImportModalOpen(true)),
      2: () => dispatch(handleSaveFileSettings({ modalType, importSuccessCallback })),
    };

    fns[step]();
  };

  const reimportFile = async (file: File) => {
    try {
      dispatch(setLoadingReimport(true));
      const { data } = await uploadFile(state.importModalErrorFallbackAnalysisId, file);
      if (homepageAnalysisList.length > 0) {
        dispatch(
          setHomepageAnalysisList(
            homepageAnalysisList.map((analysis) =>
              analysis.analysisId === state.importModalErrorFallbackAnalysisId
                ? { ...analysis, fileId: data.fileId, fileUploadStatus: FileUploadStatus.Uploading }
                : analysis
            )
          )
        );
      } else {
        dispatch(
          setAnalysisList(
            analysisListPageAnalysisList.map((analysis) =>
              analysis.analysisId === state.importModalErrorFallbackAnalysisId
                ? { ...analysis, fileId: data.fileId, fileUploadStatus: FileUploadStatus.Uploading }
                : analysis
            )
          )
        );
      }
      dispatch(setImportModalFileId(data.fileId));
    } catch (e) {
      dispatch(setHasImportError(true));
    } finally {
      dispatch(setLoadingReimport(false));
    }
  };

  useEffect(() => {
    if (!state.importModalOpen || !fileUploadStatus) return;

    if (modalType === DataSourceModalType.ImportFile && state.importModalFileId) {
      dispatch(fetchFileSummary(state.importModalFileId));
    }
    if (modalType === DataSourceModalType.ViewData && state.importModalAnalysisId) {
      dispatch(fetchAllAnalysisFilesSummary(state.importModalAnalysisId));
    }
  }, [
    dispatch,
    modalType,
    fileUploadStatus,
    state.importModalAnalysisId,
    state.importModalFileId,
    state.importModalOpen,
  ]);

  useEffect(() => {
    if (
      modalType === DataSourceModalType.ImportFile &&
      importSuccessCallback &&
      state.importModalOpen &&
      !state.error &&
      ((!state.isImportMoreData && fileUploadStatus === FileUploadStatus.Completed) ||
        (state.isImportMoreData &&
          state.fileUploadStatusFromFileFetch === FileUploadStatus.Completed &&
          fileUploadStatus === FileUploadStatus.Completed))
    ) {
      importSuccessCallback && importSuccessCallback();
      dispatch(resetDataSource());
    }
  }, [
    dispatch,
    modalType,
    fileUploadStatus,
    state.importModalOpen,
    state.isProcessingFinalStep,
    state.error,
    importSuccessCallback,
    state.isImportMoreData,
    state.fileUploadStatusFromFileFetch,
  ]);

  const renderMainContent = () => {
    switch (step) {
      case 1:
        return fileImportFailed ? <UploadFailedScreen /> : <UploadFileStep />;
      case 2:
        return (
          <ImportSettingsStep
            modalType={modalType}
            isDemographicSelectionDisabled={state.isProcessingFinalStep}
          />
        );
      default:
        return null;
    }
  };

  const isSavingDisabled =
    state.error !== null ||
    step === 1 ||
    state.isProcessingFinalStep ||
    (modalType === DataSourceModalType.ImportFile &&
      ![
        FileUploadStatus.Completed,
        FileUploadStatus.WaitingForInput,
        FileUploadStatus.ImportValidationError,
      ].includes(fileUploadStatus)) ||
    (modalType === DataSourceModalType.ViewData && fileUploadStatus !== FileUploadStatus.Completed);

  const saveCTAResourceKey = {
    [DataSourceModalType.ImportFile]: "button.next",
    [DataSourceModalType.ViewData]: "button.save",
  }[modalType];

  return (
    <Modal isOpen={state.importModalOpen} styles={modalStyles}>
      <StyledImportProgressModalContent importFailed={fileImportFailed}>
        {renderMainContent()}
        <StyledActionsSection>
          <Button variant={ButtonVariant.outline} size={ButtonSize.md} onClick={handleCloseModal}>
            <Text resource="button.close" />
          </Button>
          {step === 2 && !fileImportFailed && (
            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.md}
              disabled={isSavingDisabled}
              loading={state.isProcessingFinalStep}
              onClick={handleSave}
            >
              <Text resource={saveCTAResourceKey} />
            </Button>
          )}
          {fileImportFailed && (
            <>
              <Button
                variant={ButtonVariant.primary}
                size={ButtonSize.md}
                loading={state.loadingReimport}
                onClick={() => inputFileRef.current.click()}
              >
                <Text resource="modal.import.failed.reImport" />
              </Button>
              <FileUploadInput
                inputFileRef={inputFileRef}
                onSelectFile={reimportFile}
                resetFileRef={state.hasImportError}
              />
            </>
          )}
        </StyledActionsSection>
      </StyledImportProgressModalContent>
    </Modal>
  );
};

const StyledImportProgressModalContent = styled.div<{ importFailed: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ importFailed }) => (importFailed ? "738px" : "1170px")};
  height: ${({ importFailed }) => (importFailed ? "468px" : "680px")};
  ${({ importFailed }) =>
    !importFailed &&
    css`
      @media (max-width: 1366px) {
        width: 950px;
        height: 680px;
      }
    `}
`;

const StyledActionsSection = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 24px;
`;
