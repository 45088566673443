import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";

import { formatSearchForFetchRequest } from "utils/formatters";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { AppState } from "store/appReducer";
import { RedactionTableApiData, RedactionVersion } from "ts/redaction";
import {
  createRedactionSet,
  createRedactionVersion,
  getAnalysisRedactionVersions,
  getRedactionLists,
  getRedactionSet,
  getRedactionVersion,
  updateAnalysisRedactionVersion,
  updateRedactionSet,
  updateRedactionVersion,
} from "services/redaction";
import { setEmptyStateType } from "./redactionListSlice";
import {
  RedactionListSortingParameter,
  RedactionListType,
  SortingOrder,
} from "@explorance/mly-types";
import { setIsValidToSave, setRedactionSetId } from "./redactionBuilderSlice";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { unique } from "utils/unique";

import { RadioOption } from "ts/radio";

export type RedactionSetType = {
  name: string;
  type: RedactionListType;
  termCount: number;
  terms: string[];
  includeVariations?: boolean;
};

export const fetchRedactionLists = createAsyncThunk<
  RedactionTableApiData,
  void,
  { state: AppState }
>(
  "redaction/fetchRedactionLists",
  async (_, { getState, dispatch }): Promise<RedactionTableApiData> => {
    const state = getState();
    const redactionListState = state.redactionList;
    const searchTerm = state.search.searchTerm;

    const { data: redactionTableApiData } = await getRedactionLists(
      redactionListState.sortColumn,
      redactionListState.sortOrder,
      formatSearchForFetchRequest(searchTerm),
      DEFAULT_LIST_LIMIT,
      redactionListState.currentPage
    );

    const emptyStateType =
      redactionTableApiData.itemCount === 0 && searchTerm?.length === 0
        ? EmptyStateType.noListResults
        : redactionTableApiData.itemCount === 0 && searchTerm.length > 0
        ? EmptyStateType.noSearchResults
        : null;

    dispatch(setEmptyStateType(emptyStateType));

    return redactionTableApiData;
  }
);

export const fetchRedactionSet = createAsyncThunk<RedactionSetType, void, { state: AppState }>(
  "fetchRedactionSet",
  async (_, { getState }): Promise<RedactionSetType> => {
    const state = getState();
    return (await getRedactionSet(state.redactionBuilder.redactionSetId)).data;
  }
);

export const saveRedactionSet = createAsyncThunk<void, void, { state: AppState }>(
  "saveRedactionSet",
  async (_, { getState, dispatch }) => {
    const state = getState();
    try {
      const requestBody = {
        name: state.redactionBuilder.redactionFileName,
        type: state.redactionBuilder.redactionType,
        terms: unique(state.redactionBuilder.inputtedRedactionList),
        includeVariations: state.redactionBuilder.isVariationCheckboxChecked,
      };
      const { data } = await createRedactionSet(requestBody);
      dispatch(setRedactionSetId(Number(data.redactionSetId)));
      dispatch(setIsValidToSave(true));
      dispatch(showToastSuccess("toast.saveRedactionSuccessful"));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    }
  }
);

export const editRedactionSet = createAsyncThunk<void, number, { state: AppState }>(
  "editRedactionSet",
  async (id: number, { getState, dispatch }) => {
    const state = getState();

    try {
      const requestBody = {
        id,
        name: state.redactionBuilder.redactionFileName,
        terms: unique(state.redactionBuilder.inputtedRedactionList),
        includeVariations: state.redactionBuilder.isVariationCheckboxChecked,
      };
      await updateRedactionSet(requestBody);
      dispatch(showToastSuccess("toast.saveRedactionSuccessful"));
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      throw error;
    }
  }
);

export const fetchAnalysisRedactionVersions = createAsyncThunk<RedactionVersion[], number>(
  "fetchAnalysisRedactionVersions",
  async (analysisId): Promise<RedactionVersion[]> => {
    return (await getAnalysisRedactionVersions(analysisId)).data.redactionVersions;
  }
);

export const fetchRedactionSets = createAsyncThunk<
  RedactionTableApiData,
  void,
  { state: AppState }
>("fetchRedactionSets", async (_, { getState }): Promise<RedactionTableApiData> => {
  const state = getState();
  const searchTerm = state.search.searchTerm.trim();
  const { data: redactionSets } = await getRedactionLists(
    RedactionListSortingParameter.LastUpdated,
    SortingOrder.DESC,
    formatSearchForFetchRequest(searchTerm),
    1000,
    1
  );

  return redactionSets;
});

export const handleCreateRedactionVersion = createAsyncThunk<void, number, { state: AppState }>(
  "handleCreateRedactionVersion",
  async (analysisId, { getState }) => {
    const state = getState().redactionModal;

    await createRedactionVersion(analysisId, {
      name: state.redactionVersionName,
      rules: state.redactionRules,
      includeManual: state.isManualRedactionEnabled,
    });
  }
);

export const handleEditRedactionVersion = createAsyncThunk<void, void, { state: AppState }>(
  "handleEditRedactionVersion",
  async (_, { getState }) => {
    const state = getState().redactionModal;

    await updateRedactionVersion(state.redactionVersionId, {
      name: state.redactionVersionName,
      rules: state.redactionRules,
      includeManual: state.isManualRedactionEnabled,
    });
  }
);

type changeAnalysisRedactionVersionProps = {
  versionOption: RadioOption;
  analysisId: number;
};

export const changeAnalysisRedactionVersion = createAsyncThunk<
  RadioOption,
  changeAnalysisRedactionVersionProps
>("changeAnalysisRedactionVersion", async ({ versionOption, analysisId }) => {
  await updateAnalysisRedactionVersion(Number(versionOption?.value) || null, analysisId);
  return versionOption;
});

export const fetchRedactionVersion = createAsyncThunk<RedactionVersion, void, { state: AppState }>(
  "fetchRedactionVersion",
  async (_, { getState }) => {
    const state = getState();
    return (await getRedactionVersion(state.redactionModal.redactionVersionId)).data;
  }
);
