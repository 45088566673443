import React from "react";
import { useAppDispatch, useAppSelector } from "store";
import { saveRedactionSet } from "store/redaction/thunks";
import {
  setBlockRouteChange,
  setHasErrors,
  setIsValidToSave,
} from "store/redaction/redactionBuilderSlice";
import { RedactionBuilder } from "common-layouts/RedactionBuilder";

export const NewRedactionPage = () => {
  const state = useAppSelector((state) => state.redactionBuilder);
  const dispatch = useAppDispatch();

  const handleCreateRedaction = async () => {
    if (state.isLoading) return;
    if (state.inputtedRedactionList.length === 0) {
      dispatch(setHasErrors(true));
      dispatch(setIsValidToSave(false));
      return;
    }
    await dispatch(saveRedactionSet());
    dispatch(setBlockRouteChange(false));
  };

  return <RedactionBuilder handleSave={handleCreateRedaction} />;
};
