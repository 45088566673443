import React from "react";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { useAppDispatch } from "store";
import { CommentRedactionInfo, openRedactCommentModal } from "store/redaction/redactCommentSlice";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";

type Props = {
  commentRedactionInfo: CommentRedactionInfo;
};

export const RedactionActionButton = ({ commentRedactionInfo }: Props) => {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(openRedactCommentModal(commentRedactionInfo));
  };

  return (
    <Button
      variant={ButtonVariant.light}
      square
      tooltipContentKey="comments.redaction.tooltip"
      size={ButtonSize.sm}
      onClick={handleClick}
      style={{ color: Color.gray20 }}
    >
      <Icon type={IconType.lockedFile} color={Color.gray20} size={12} />
    </Button>
  );
};
