import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "routes";

import {
  clearAll,
  discardChanges,
  removeRedactionItem,
  setBlockRouteChange,
  setInputtedRedactionList,
  setIsVariationCheckboxChecked,
  setRedactionFileName,
  setRedactionType,
  setShowDiscardChangesModal,
  setShowSaveChangesModal,
} from "store/redaction/redactionBuilderSlice";
import { useAppDispatch, useAppSelector } from "store";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { voidFunc } from "utils/voidFunc";

import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";
import { Select } from "components/_inputs/Select";
import { PillInput } from "components/_inputs/PillInput";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { UnsavedChangesModal } from "components/_modals/UnsavedChangesModal";
import { Icon, IconType } from "components/_icons/Icon";
import { Checkbox } from "components/_inputs/Checkbox";
import { Tooltip } from "components/Tooltip";
import { SuggestedRedactionTerms } from "./SuggestedRedactionTerms";

import { Color } from "ts/enums/color";
import { ButtonVariant } from "ts/enums/button";
import { RedactionListType } from "@explorance/mly-types";

export const RedactionBuilder = ({ handleSave }) => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.redactionBuilder);
  const dispatch = useAppDispatch();

  const [unblockFunc, setUnblockFunc] = useState<any>();
  const inputRef = useRef<HTMLInputElement>();
  const redactionId = useParams<{ redactionId: string }>().redactionId;
  const history = useHistory();
  const { getResource } = useResource();

  const redactionTypeOptions = [
    { label: getResource("redaction.body.dropdown.user"), value: RedactionListType.User },
    { label: getResource("redaction.body.dropdown.system"), value: RedactionListType.System },
  ];

  const handleOnBlur = () => {
    if (state.redactionFileName.trim().length === 0) {
      dispatch(setRedactionFileName(state.initialRedactionSet.setName));
    }
  };

  const clickBackHandler = () => {
    if (state.hasBeenEdited) {
      history.replace(routes.redactionListPage);
    } else {
      dispatch(setBlockRouteChange(false));
      history.push(routes.redactionListPage);
    }
  };

  const onClickSaveChangesModalButton = async () => {
    dispatch(setShowSaveChangesModal(false));
    await handleSave();
    unblockFunc();
  };

  useEffect(() => {
    if (state.redactionSetId && !state.showSaveChangesModal) {
      history.replace(routes.editRedactionPage(state.redactionSetId));
    }
  }, [state.redactionSetId]); //eslint-disable-line

  useEffect(() => {
    if (state.blockRouteChange) {
      const unblock = history.block((destination) => {
        dispatch(setShowSaveChangesModal(true));
        setUnblockFunc(() => () => {
          unblock();
          history.replace(destination);
        });
        return false;
      });

      return () => {
        unblock();
      };
    }
  }, [state.blockRouteChange, history, dispatch]);

  return (
    <div className="fade-in">
      <StyledHeaderContainer>
        <StyledInputContainer>
          <StyledInputLabel>
            <Text resource="redaction.header.nameInput.label" />
          </StyledInputLabel>
          <StyledInput
            ref={inputRef}
            value={state.redactionFileName}
            onChange={(e) => {
              dispatch(setRedactionFileName(e.target.value));
            }}
            onBlur={handleOnBlur}
            autoFocus
          />

          <StyledRedactionCount>
            <Text
              resource={{
                key:
                  state.inputtedRedactionList.length === 1
                    ? "redaction.header.totalItems.singular"
                    : "redaction.header.totalItems.plural",
                args: [`${state.inputtedRedactionList.length}`],
              }}
            />
          </StyledRedactionCount>
        </StyledInputContainer>
        <StyledDescription>
          <Text resource="redaction.body.description" />
        </StyledDescription>
        {isAnyAdmin(currentUser.roleType) && (
          <StyledDropdownContainer>
            <StyledDropdownLabel>
              <Text resource="redaction.body.dropdown.label" />
            </StyledDropdownLabel>
            <Select
              buttonVariant={ButtonVariant.outlineGray}
              options={redactionTypeOptions}
              selectedOption={redactionTypeOptions.find(
                (type) => type.value === state.redactionType
              )}
              dropdownWidth="135px"
              buttonStyle={{
                width: "135px",
                fontWeight: "normal",
                fontSize: "14px",
                gap: "12px",
              }}
              disabled={!!redactionId}
              handleChange={(type) => dispatch(setRedactionType(type))}
            />
          </StyledDropdownContainer>
        )}
      </StyledHeaderContainer>
      <StyledInputsContainer>
        <PillInput
          pillList={state.inputtedRedactionList}
          inputHeaderKey="redaction.body.header"
          placeholderKey="redaction.input.placeholder"
          removeListItem={({ pillItem, index }) =>
            dispatch(removeRedactionItem({ redaction: pillItem, index }))
          }
          submitListItem={(listItem) => {
            dispatch(setInputtedRedactionList(listItem));
            dispatch(setBlockRouteChange(true));
          }}
          hasErrors={state.hasErrors}
          clearAll={() => dispatch(clearAll())}
          height={"94%"}
          inputMaxLength={100}
        />
        <SuggestedRedactionTerms />
      </StyledInputsContainer>
      <StyledCheckboxContainer
        onClick={() => {
          dispatch(setIsVariationCheckboxChecked(!state.isVariationCheckboxChecked));
        }}
      >
        <Checkbox
          checked={state.isVariationCheckboxChecked}
          onChange={voidFunc}
          onClickInput={(e) => e.stopPropagation()}
        />
        <Text resource="redaction.createEdit.checkbox.label" />
        <Icon type={IconType.info} size={12} data-tooltip-id="variation-tooltip" />
        <Tooltip
          tooltipId="variation-tooltip"
          content={<Text resource="redaction.tooltip.variations" />}
          fontSize="0.785em"
        />
      </StyledCheckboxContainer>

      <StyledActionsContainer>
        <div>
          <Button variant={ButtonVariant.outline} onClick={clickBackHandler}>
            <Text resource="redaction.button.back" />
          </Button>

          {state.hasErrors && (
            <StyledErrorLabel>
              <Icon type={IconType.info} size={12} color={Color.red50} />
              <Text resource="redaction.error.emptyList" />
            </StyledErrorLabel>
          )}
        </div>
        <div>
          <Button
            variant={ButtonVariant.light}
            onClick={() => {
              dispatch(setShowDiscardChangesModal(true));
            }}
            disabled={!state.hasBeenEdited}
          >
            <Text resource="button.discardChanges" />
          </Button>

          <Button disabled={!state.hasBeenEdited} loading={state.isLoading} onClick={handleSave}>
            <Text resource="button.saveChanges" />
          </Button>
        </div>
      </StyledActionsContainer>
      <ActionConfirmationModal
        onClickActionButton={() => dispatch(discardChanges())}
        isOpen={state.showDiscardChangesModal}
        actionButtonLabel={<Text resource="button.discard" />}
        onCancel={() => {
          dispatch(setShowDiscardChangesModal(false));
        }}
        title={
          <Text
            resource={{
              key: "modal.discardChanges.title",
              args: [state.redactionFileName],
            }}
          />
        }
      />
      {state.showSaveChangesModal && (
        <UnsavedChangesModal
          onDiscard={() => unblockFunc()}
          onClose={() => dispatch(setShowSaveChangesModal(false))}
          onSave={onClickSaveChangesModalButton}
          captionTitle={
            <Text
              resource={{
                key: "modal.unsavedChanges.title",
                args: [state.redactionFileName],
              }}
            />
          }
        />
      )}
    </div>
  );
};

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 24px;
`;

const StyledInputContainer = styled.div`
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledInputLabel = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: ${Color.gray50};
`;

const StyledRedactionCount = styled.div`
  font-size: 14px;
  color: ${Color.gray50};
`;

const StyledDescription = styled.div`
  font-size: 14px;
  color: ${Color.gray50};
  margin-bottom: 12px;
`;

const StyledDropdownLabel = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: ${Color.gray50};
`;

const StyledDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
  div {
    display: flex;
    gap: 12px;
  }
`;

const StyledInputsContainer = styled.div`
  height: 57vh;
  width: 100%;
  display: flex;
  gap: 24px;
`;

const StyledCheckboxContainer = styled.div`
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: bold;
  min-height: 38px;
`;

const StyledErrorLabel = styled.span`
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: ${Color.red50};
`;

const StyledInput = styled.input`
  border: 1px solid ${Color.sky50};
  color: ${Color.gray50};
  height: 34px;
  width: 500px;
  border-radius: 2px;
  padding: 8px 10px;
  &:focus {
    border: 1px solid ${Color.blue50};
    outline: none;
  }
`;
