import React, { useState } from "react";
import styled from "styled-components";

import { UserDemographic } from "context/UserProfileContext";
import { useResource } from "hooks/useResource";

import { Icon, IconType } from "components/_icons/Icon";
import { Tooltip } from "components/Tooltip";
import { Text } from "components/Text";

import { RedactionCustomCondition } from "ts/sharing";
import { DemographicFilterHeader } from "ts/filters/demographicFilter";
import { CustomRedactionCondition } from "./CustomRedactionCondition";

type Props = {
  savedCustomConditions: RedactionCustomCondition[];
  analysisDemographicHeaders: DemographicFilterHeader[];
  userDemographics: UserDemographic[];
  // TODO: will revert optional chaining in subsequent error handling PR
  showCustomConditionError?: boolean;
  handleHideCustomConditionError?: () => void;
  updateRedactionCustomConditions?: (
    updatedCustomConditions: RedactionCustomCondition[],
    noSave?: boolean
  ) => void;
};

export const CustomRedactionConditionSection = ({
  savedCustomConditions,
  analysisDemographicHeaders,
  userDemographics,
  updateRedactionCustomConditions,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const { getResource } = useResource();

  return (
    <div>
      <StyledTitleContainer>
        <b>
          <Text resource="sharing.permissionForm.customCondition.title" />
        </b>
        <StyledIconContainer
          data-tooltip-id="custom-condition-tooltip"
          onMouseOver={() => setShowTooltip(true)}
          onMouseOut={() => setShowTooltip(false)}
        >
          <Icon type={IconType.info} size={10} />
          {showTooltip && (
            <Tooltip
              tooltipId="custom-condition-tooltip"
              content={getResource("sharing.redaction.customCondition.tooltip")}
            />
          )}
        </StyledIconContainer>
      </StyledTitleContainer>
      <StyledCustomConditionContainer>
        {savedCustomConditions.map((savedCondition, index) => (
          <CustomRedactionCondition
            key={index}
            index={index}
            customConditionRedactions={savedCondition}
            savedCustomConditions={savedCustomConditions}
            analysisDemographicHeaders={analysisDemographicHeaders}
            userDemographics={userDemographics}
            updateRedactionCustomConditions={updateRedactionCustomConditions}
          />
        ))}
      </StyledCustomConditionContainer>
    </div>
  );
};

const StyledCustomConditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  gap: 2px;
  font-size: 15px;
  margin: 6px 0;
`;

const StyledIconContainer = styled.div`
  display: flex;
`;
