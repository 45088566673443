import { createSlice } from "@reduxjs/toolkit";

export type CommentRedactionInfo = {
  value: string;
  isHidden: boolean;
  isRemoved: boolean;
};

type RedactCommentState = {
  isRedactCommentModalOpen: boolean;
  originalComment: CommentRedactionInfo;
  redactedComment: CommentRedactionInfo;
};

const initialState: RedactCommentState = {
  isRedactCommentModalOpen: false,
  originalComment: null,
  redactedComment: null,
};

const RedactCommentSlice = createSlice({
  name: "redactCommentSlice",
  initialState,
  reducers: {
    openRedactCommentModal: (state, action) => {
      state.isRedactCommentModalOpen = true;
      state.originalComment = action.payload;
      state.redactedComment = action.payload;
    },
    closeRedactCommentModal: () => initialState,
    setRedactedCommentValue: (state, action) => {
      state.redactedComment.value = action.payload;
    },
    hideComment: (state) => {
      state.redactedComment.isHidden = !state.redactedComment.isHidden;
      state.redactedComment.isRemoved = false;
      state.redactedComment.value = state.originalComment.value;
    },
    removeComment: (state) => {
      state.redactedComment.isRemoved = !state.redactedComment.isRemoved;
      state.redactedComment.isHidden = false;
      state.redactedComment.value = state.originalComment.value;
    },
  },
});

export const {
  openRedactCommentModal,
  closeRedactCommentModal,
  setRedactedCommentValue,
  hideComment,
  removeComment,
} = RedactCommentSlice.actions;

export default RedactCommentSlice.reducer;
