import React, { ReactNode } from "react";
import styled from "styled-components";
import ReactModal from "react-modal";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

type Props = {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
  styles?: ReactModal.Styles;
  width?: string;
  height?: string;
  maxHeight?: string;
  padding?: string;
  margin?: string;
  overlayIndex?: ZIndexStackingContext;
  overlayBgColor?: string;
  closeOnOverlayClick?: boolean;
  handleRequestCloseFunc?(e: React.MouseEvent): void;
};

export const Modal = ({
  isOpen,
  children,
  className,
  styles,
  width,
  height,
  maxHeight,
  padding,
  margin,
  overlayIndex,
  overlayBgColor,
  closeOnOverlayClick = true,
  handleRequestCloseFunc,
}: Props) => {
  const customStyles = {
    overlay: {
      backgroundColor: overlayBgColor ?? "rgba(237, 237, 237, 0.6)",
      zIndex: overlayIndex ?? ZIndexStackingContext.high,
    },
    content: styles?.content ?? {
      position: "relative",
      boxShadow: "0px 4px 16px #B6BACB29",
      margin: margin ?? "15% auto",
      left: "0px",
      right: "0px",
      width: width ?? "450px",
      height: height ?? "155px",
      maxHeight: maxHeight,
      padding: padding ?? "20px",
      border: `1px solid ${Color.neutral30}`,
      borderRadius: "5px",
      backgroundColor: Color.white,
    },
  };

  return (
    <ReactModal
      closeTimeoutMS={200}
      isOpen={isOpen}
      style={customStyles}
      className={className}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      onRequestClose={(e: React.MouseEvent) => handleRequestCloseFunc(e)}
    >
      <div>{children}</div>
    </ReactModal>
  );
};

export const StyledModalTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const StyledModalCaption = styled.div`
  color: ${Color.gray40};
  font-size: 13px;
  margin-bottom: 20px;
`;
