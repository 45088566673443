import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
  clearState,
  setBlockRouteChange,
  setHasErrors,
  setIsValidToSave,
  setRedactionSetId,
} from "store/redaction/redactionBuilderSlice";
import { editRedactionSet, fetchRedactionSet } from "store/redaction/thunks";
import { RedactionBuilder } from "common-layouts/RedactionBuilder";

export const EditRedactionPage = () => {
  const state = useAppSelector((state) => state.redactionBuilder);
  const dispatch = useAppDispatch();

  const redactionId = useParams<{ redactionId: string }>().redactionId;

  useEffect(() => {
    if (redactionId) {
      dispatch(setRedactionSetId(Number(redactionId)));
      dispatch(fetchRedactionSet());
    }
    return () => {
      dispatch(clearState());
    };
  }, []); //eslint-disable-line

  const handleUpdateRedaction = async () => {
    if (state.isLoading) return;
    if (state.inputtedRedactionList.length === 0) {
      dispatch(setHasErrors(true));
      dispatch(setIsValidToSave(false));
      return;
    }
    await dispatch(editRedactionSet(Number(redactionId)));
    dispatch(setBlockRouteChange(false));
  };

  return <RedactionBuilder handleSave={handleUpdateRedaction} />;
};
