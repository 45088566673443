import React, { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { useResource } from "hooks/useResource";
import { UserDemographic } from "context/UserProfileContext";
import { useAppDispatch, useAppSelector } from "store";
import { fetchRedactionVersions } from "store/sharing/thunks";

import { Text } from "components/Text";
import { Select } from "components/_inputs/Select";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { RadioGroup } from "components/_inputs/RadioGroup";
import { CustomRedactionConditionSection } from "./CustomRedactionConditionSection";

import { Color } from "ts/enums/color";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { RedactionCustomCondition } from "ts/sharing";
import { DemographicFilterHeader } from "ts/filters/demographicFilter";

import { setManualRedactionPermissionRadioOption } from "store/sharing/editSharingBlockSlice";
import { RedactionVersion } from "ts/redaction";

type Props = {
  analysisDemographicHeaders: DemographicFilterHeader[];
  selectedCustomConditions: RedactionCustomCondition[];
  userDemographics: UserDemographic[];
  selectedRedactionVersion: RedactionVersion;
  updateRedactionCustomConditions: (
    updatedCustomConditions: RedactionCustomCondition[],
    noSave?: boolean
  ) => void;
  updateSelectedRedactionVersion: (selectedRedactionVersion: RedactionVersion) => void;
};

export const VisibleRedactionVersion = ({
  analysisDemographicHeaders,
  selectedCustomConditions,
  userDemographics,
  selectedRedactionVersion,
  updateRedactionCustomConditions,
  updateSelectedRedactionVersion,
}: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.sharing);
  const editSharingBlockState = useAppSelector((state) => state.editSharingBlock);

  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);

  const { getResource } = useResource();

  const redactionVersionDropdownOptions = state.redactionVersions?.map((version) => ({
    label: version.name,
    value: version.id?.toString(),
  }));

  const radioOptions = [
    {
      label: <Text resource="redaction.sharingPermissions.radioOption.cantRedact" />,
      value: getResource("redaction.sharingPermissions.radioOption.cantRedact"),
    },
    {
      label: <Text resource="redaction.sharingPermissions.radioOption.allowRedactionLimited" />,
      value: getResource("redaction.sharingPermissions.radioOption.allowRedactionLimited"),
    },
    {
      label: <Text resource="redaction.sharingPermissions.radioOption.allowRedaction" />,
      value: getResource("redaction.sharingPermissions.radioOption.allowRedaction"),
    },
  ];

  const addCustomCondition = () => {
    updateRedactionCustomConditions(
      [
        ...selectedCustomConditions,
        { redactionVersion: "", analysisDemographic: "", userDemographic: "" },
      ],
      false
    );
  };

  useEffect(() => {
    if (analysisId) dispatch(fetchRedactionVersions(analysisId));
  }, []); // eslint-disable-line

  return (
    <>
      <StyledTitleContainer>
        <Text resource="sharing.permissionForm.redactionVersion.label" />
      </StyledTitleContainer>
      <StyledRedactionBlockContainer>
        <StyledFirstConditionRow>
          <StyledDropdownContainer>
            <Select
              options={redactionVersionDropdownOptions}
              selectedOption={{
                label: selectedRedactionVersion?.name,
                value: selectedRedactionVersion?.name,
              }}
              handleChange={updateSelectedRedactionVersion}
              buttonStyle={{ width: "100%" }}
              selectStyle={{ width: "100%" }}
              dropdownWidth="100%"
              placeholderKey="sharing.permissionForm.customCondition.defaultRedactionVersion.label"
            />
          </StyledDropdownContainer>
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
            style={{ height: "28px", gap: "6px", whiteSpace: "nowrap" }}
            onClick={addCustomCondition}
          >
            <Icon type={IconType.gear} size={12} />
            <Text resource="button.addCustomCondition" />
          </Button>
        </StyledFirstConditionRow>
        {selectedCustomConditions.length > 0 && (
          <CustomRedactionConditionSection
            savedCustomConditions={selectedCustomConditions}
            analysisDemographicHeaders={analysisDemographicHeaders}
            userDemographics={userDemographics}
            updateRedactionCustomConditions={updateRedactionCustomConditions}
          />
        )}
        <StylesRadioGroupContainer>
          <StyledRadioLabel>
            <Text resource="redaction.sharingPermissions.radioLabel" />
          </StyledRadioLabel>
          <RadioGroup
            selectedOption={
              editSharingBlockState.manualRedactionPermissionRadioOption ?? radioOptions[0]
            }
            options={radioOptions}
            onChange={(e) => dispatch(setManualRedactionPermissionRadioOption(e))}
            customStyles={{ radioLabelWidth: "360px" }}
          />
        </StylesRadioGroupContainer>
      </StyledRedactionBlockContainer>
    </>
  );
};

const StyledRedactionBlockContainer = styled.div`
  background-color: ${Color.neutral10};
  border-radius: 5px;
  padding: 12px 24px;
`;

const StyledTitleContainer = styled.div`
  font-weight: bold;
  font-size: 15px;
  padding-bottom: 6px;
`;

const StyledDropdownContainer = styled.div`
  width: 84%;
  margin-bottom: 6px;
`;

const StyledRadioLabel = styled.div`
  color: ${Color.gray50};
  font-size: 15px;
  font-weight: bold;
`;

const StyledFirstConditionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const StylesRadioGroupContainer = styled.div`
  margin-top: 6px;
`;
